(function ($) {
  $(function () {
    //Resources slider
    $(".resources-list").each(function (index, sliderWrap) {
      let $slider = $(sliderWrap).find(".resource_slider");
      let $progressBar = $(sliderWrap).find(".progress");
      let $progressBarLabel = $(sliderWrap).find(".slider__label");

      // $slider.on("init", function (event, slick, currentSlide, nextSlide) {
      //   let calc = (100 / slick.slideCount) * (slick.slideCount - 1);
      //   console.log("count : " + slick.slideCount);
      //   console.log("calc : " + calc);

      //   $progressBar
      //     .css("background-size", calc + "% 100%")
      //     .attr("aria-valuenow", calc);
      // });

      // $slider.on(
      //   "beforeChange",
      //   function (event, slick, currentSlide, nextSlide) {
      //     let calc =
      //       (100 / slick.slideCount) * (slick.slideCount - 1) +
      //       100 / slick.slideCount;

      //     console.log("current : " + currentSlide);
      //     console.log("next : " + nextSlide);

      //     $progressBar
      //       .css("background-size", calc + "% 100%")
      //       .attr("aria-valuenow", calc);

      //     $progressBarLabel.text(calc + "% completed");
      //   }
      // );
      $slider.on(
        "beforeChange",
        function (event, slick, currentSlide, nextSlide) {
          var calc = (nextSlide / (slick.slideCount - 1)) * 100;

          $progressBar
            .css("background-size", calc + "% 100%")
            .attr("aria-valuenow", calc);

          $progressBarLabel.text(calc + "% completed");
        }
      );

      $slider.slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        autoplay: false,
        infinite: true,
        prevArrow: $(sliderWrap).find(".arrow-holder .prev"),
        nextArrow: $(sliderWrap).find(".arrow-holder .next"),

        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              //centerMode: true
            },
          },
          {
            breakpoint: 720,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true,
              infinite: true,
            },
          },
        ],
      });
    });

    //Case studies slider
    //Only enabled on mobile
    // if (document.querySelector("#case-studies-slider")) {
    //   const element = document.querySelector("#case-studies-slider");
    //   const breakpoint = 767; // define your breakpoint value

    //   const mediaQueryList = window.matchMedia(`(max-width: ${breakpoint}px)`);

    //   if (mediaQueryList.matches) {
    //     element.classList.add("case-studies-slider"); // add the CSS class to the element
    //   }
    // }

    // $(".case-studies-slider").slick({
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   dots: false,
    //   arrows: false,
    //   autoplay: false,
    //   autoplaySpeed: 4000,
    //   centerMode: true,
    //   centerPadding: "10%",
    //   infinite: false,
    // });

    //logo slider
    // $(".autoslide").slick({
    //   slidesToShow: 5,
    //   slidesToScroll: 1,
    //   dots: false,
    //   arrows: false,
    //   autoplay: true,
    //   autoplaySpeed: 2000,
    //   centerMode: true,
    //   centerPadding: "120px",
    //   responsive: [
    //     {
    //       breakpoint: 1440,
    //       settings: {
    //         slidesToShow: 3,
    //         slidesToScroll: 1,
    //         dots: false,
    //         centerMode: true,
    //         centerPadding: "120px",
    //       },
    //     },
    //     {
    //       breakpoint: 1200,
    //       settings: {
    //         slidesToShow: 3,
    //         slidesToScroll: 1,
    //         dots: false,
    //         centerMode: true,
    //         centerPadding: "120px",
    //       },
    //     },
    //     {
    //       breakpoint: 992,
    //       settings: {
    //         slidesToShow: 3,
    //         slidesToScroll: 1,
    //         dots: false,
    //         centerMode: true,
    //         centerPadding: "40px",
    //       },
    //     },
    //     {
    //       breakpoint: 768,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         dots: false,
    //         centerMode: true,
    //         centerPadding: "120px",
    //       },
    //     },
    //   ],
    // });

    //Post
    // $(".slider-featured-post").slick({
    //   slidesToShow: 3,
    //   slidesToScroll: 1,
    //   dots: false,
    //   arrows: false,
    //   autoplay: false,
    //   autoplaySpeed: 2000,
    //   responsive: [
    //     {
    //       breakpoint: 768,
    //       settings: {
    //         slidesToShow: 2,
    //         dots: true,
    //         centerMode: false,
    //       },
    //     },
    //     {
    //       breakpoint: 560,
    //       settings: {
    //         slidesToShow: 1,
    //         dots: true,
    //         centerMode: true,
    //         centerPadding: "65px",
    //       },
    //     },
    //   ],
    // });

    // sectors carousel
    // $(".sectors-carousel-js").each(function () {
    //   $(this).slick({
    //     slidesToShow: 5,
    //     slidesToScroll: 1,
    //     centerMode: true,
    //     centerPadding: "165px",
    //     dots: false,
    //     arrows: true,
    //     autoplay: false,
    //     speed: 900,
    //     autoplaySpeed: 4000,
    //     adaptiveHeight: false,
    //     prevArrow: $(".sectors-carousel-prev", this),
    //     nextArrow: $(".sectors-carousel-next", this),
    //   });
    // });

    // Content carousel
    // $(".content-carousel-js").each(function () {
    //   $(this).slick({
    //     dots: false,
    //     arrows: true,
    //     autoplay: false,
    //     speed: 900,
    //     autoplaySpeed: 4000,
    //     adaptiveHeight: true,
    //     prevArrow: $(".content-carousel-prev", this),
    //     nextArrow: $(".content-carousel-next", this),
    //   });
    // });

    // Business carousel
    // $(".team-carousel-js").slick({
    //   slidesToShow: 4,
    //   slidesToScroll: 1,
    //   dots: false,
    //   arrows: true,
    //   autoplay: false,
    //   autoplaySpeed: 2000,
    //   prevArrow: $(".team-carousel-prev", this),
    //   nextArrow: $(".team-carousel-next", this),
    //   responsive: [
    //     {
    //       breakpoint: 1400,
    //       settings: {
    //         slidesToShow: 3,
    //         centerMode: false,
    //       },
    //     },
    //     {
    //       breakpoint: 992,
    //       settings: {
    //         slidesToShow: 2,
    //         centerMode: false,
    //       },
    //     },
    //     {
    //       breakpoint: 560,
    //       settings: {
    //         slidesToShow: 1,
    //         centerMode: true,
    //       },
    //     },
    //   ],
    // });

    //Hide carousel nav if less than 5 team members (as there are 4 items to scroll)
    // if ($(".team-carousel-js .slick-slide").length < 5) {
    //   $(".team-carousel-nav").addClass("d-none");
    // }
  });
})(jQuery);
